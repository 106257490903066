"use client";

import { api } from "@/api";
import type { Incident, IncidentFilter } from "@/types";
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";

type Request = {
  filter?: IncidentFilter;
};

type Response = {
  total: number;
  incidents: Incident[];
};

export function useIncidents(
  { filter }: Request,
  options: Partial<UseInfiniteQueryOptions<Response, Error>> | void,
) {
  return useInfiniteQuery<Response, Error, Response>({
    queryKey: ["incidents", { filter }],
    initialPageParam: null,
    refetchInterval: 2000,
    refetchIntervalInBackground: true,

    queryFn: async ({ pageParam: after, signal }) => {
      const {
        datesRelativeTo,
        creator,
        comments,
        latitude,
        longitude,
        radius,
        incidentStatus,
        category,
        from,
        to,
        limit,
        keywords,
        legal,
        location,
        presets,
        full,
        active,
        line1,
        line2,
        city,
        county,
        state,
        incidentId,
        alarm,
        propertyAddress,
        legalDetails,
        stateIds,
        countyInclusions,
        countyExclusions,
        cityInclusions,
        cityExclusions,
        allCountyStateIds,
      } = filter ?? {};

      let params = {
        datesRelativeTo,
        creator,
        comments,
        latitude,
        longitude,
        radius,
        incidentStatus,
        category,
        after,
        limit,
        keywords,
        legal,
        location,
        presets,
        from,
        to,
        full,
        active,
        line1,
        line2,
        city,
        county,
        state,
        incidentId,
        alarm,
        propertyAddress,
        legalDetails,
        stateIds,
        countyInclusions,
        countyExclusions,
        cityInclusions,
        cityExclusions,
        allCountyStateIds,
      };

      for (const [key, value] of Object.entries(params)) {
        if (value === undefined) {
          // @ts-ignore
          delete params[key];
        }

        if (typeof value === "boolean") {
          // @ts-ignore
          params[key] = value ? "1" : "";
        }
      }

      const response = await api.get<Response>("incidents", {
        params,
        signal,
      });

      return response.data;
    },

    getNextPageParam: (response) => {
      const oldestIncident = response.incidents[response.incidents.length - 1];

      if (oldestIncident) {
        return oldestIncident.createdAt;
      }
    },

    ...options,
  });
}
